import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'Our Vision',
    content: () => (
      <>
        The Elijah Trust exists to glorify God by sharing the hope we have in God. We share that hope by offering employment, belonging and community to individuals with special needs. To provide comfort to families of special needs individuals by ensuring their loved one lives a meaningful and productive life in a loving supportive environment when they are no longer able to.
      </>
    ),
  },
  {
    title: 'Our Values',
    content: () => (
      <>
        We believe that all people have innate value as image bearers of God. That first and foremost this is ministry to service God’s people and everything we do must be an act of worship and service to Him. We believe in working hard unto the Lord for people of all abilities.
      </>
    ),
  },
  {
    title: 'Our Founders',
    content: () => (
      <>
        GThe Elijah Trust was founded by Gilbert and Sandra Villarreal.  They are the parents of three children, two of them with special needs. This impacted every aspect of their lives and consumed their thoughts of the future.  Their goal has been to share hope – hope in God with other families facing similar circumstances and bring hope of a bright future for their children.
      </>
    ),
  },
  {
    title: 'Why Elijah?',
    content: () => (
      <>
        Sandra’s two boys are almost a decade apart. Their oldest son is the primary reason this organization was created. She knew because of the of the severity of his disability, she is always concerned about what's going to happen to my children when I'm gone? I'm a mom, he's my baby, so this is named in His honor and for him. And this is not just in his honor, we know here are so many other parents who think about the same things. 
        
        We like to think and set up a structure for people like Sandra’s boys to develop a facility to be self-sufficient. Where they can learn animal husbandry, learn gardening, and can have a professional staff to help them. These are the kinds of things we have planned; the Lord will direct our paths.
        
        Everything keeps going back to Eli's Balms - Eli's Hope - hope in God. Eli is a shorter form of the Hebrew word for God in the Old Testament. 

      </>
    ),
  },
  {
    title: 'What is Eli\'s Balms',
    content: () => (
      <>
        We’re starting with what we have, all natural and high-quality balms and ointments created by Sandra and we’re selling these. Sandra's boys both struggled with skin irritation (a common occurrence for people on the spectrum) and it was a struggle to find creams and balms that worked well and wouldn't cause other issues. In her research, she found some organic and natural products and found that these worked best. Even more amazing, she realized that because these were made from very simple, 3-4 ingredient recipes, she could make these products for her boys at home.
        This is what became Eli's Balms. She even found her boys a productive outlet as they help create and package each of the products. Elijah's Balms is growing! Right now, we are focused on growing our product offering and distribution as we turn our eyes to our main goal, building <strong>Rancho Real</strong>.

      </>
    ),
  },
  {
    title: 'What is Rancho Real',
    content: () => (
      <>
        One day, we would love to have acreage and a farm/ranch. It doesn't need to be something where we're going to the farmers market and selling eggs. We want it to be therapeutic - hippotherapy. There's so much when someone feels useful. 
        
        Rancho Real will be our home for ministry for people on the spectrum right here in Southern California. We plan to build a state of the art residential and retreat center and ranch. It will be the forever home for adults on the spectrum, and a place where families with children on the spectrum can come to find rest, refreshment, and community. It will provide meaningful and productive work for residents on the ranch and in manufacturing and distributing the products sold by Eli's Balms and it will serve as the home base for a wider ministry effort.
      </>
    ),
  },
];

const Faq = () => (
  <Section id="about">
    <Container>
      <h1 style={{ marginBottom: 40 }}>About Us</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
